<template>
  <div></div>
  <!--<exception-page type="404" />-->
</template>

<script>
import { ExceptionPage } from '@/components'
import { loadPermissionList } from '@/api/data'
import moment from 'moment'

export default {
  components: {
    ExceptionPage
  },
  created () {
    loadPermissionList({ pageNo: 1, pageSize: 100 }).then(res => {
      const ds = res.result.data
      let expireNum = 0
      for (const d of ds) {
        if (d.user_level === 0 || moment(d.level_expire).diff(moment(), 'days') <= 5) {
          expireNum++
        }
      }
      if (expireNum > 0) {
        window.location.href = '/#/driver/list'
      } else {
        window.location.href = '/#/test/record'
      }
    })
  }
}
</script>

<style scoped>

</style>
